import React, { useState, useEffect } from 'react';

import {
    Stack,
    Container,
    Typography,
    Card,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Box,
    Grid,
    FormLabel,
    TextField,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Input
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useFormik, useField, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../Redux/Selectors/UserSelectors';
import { postSubscriptionData, editSubscriptionData } from '../Redux/Actions/UserActions';
import Page from '../components/Page';


const CreateNewRecords = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [descriptionData, setDescriptionData] = useState((location?.state?.description) ? location?.state?.description : [])
    const [newDescription, setNewDescription] = useState('');
    const [descriptionDataSend, setDescriptionDataSend] = useState((location?.state?.description) ? location?.state?.description.slice(0) : [])
    const dispatch = useDispatch();
    const accessToken = useSelector(getToken);
    const [yearPrice,setYearPrice]=useState(location?.state?.yearPrice || 0);
    const [monthPrice,setMonthPrice]=useState(location?.state?.monthPrice || 0);
    const validate = values => {
        /* eslint-disable no-debugger */
    debugger;
    /* eslint-enable no-debugger */
        const errors = {};
        if (!values.title) {
            errors.title = 'Plan Name is required';
        }
        if (!monthPrice) {
            errors.monthPrice = 'Monthly Price is required';
        }
        if (!yearPrice) {
            errors.yearPrice = 'Yearly Price is required';
        }
        if (!descriptionDataSend.length) {
            errors.description = 'Description is required to add ';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            title: location?.state?.title ?? '',
            monthPrice: location?.state?.monthPrice ?? '',
            yearPrice: location?.state?.yearPrice ?? '',
            description: !location?.state?.description ? [] : descriptionData
        },
        validate,
        onSubmit: values => {
            if (location?.state) {
                dispatch(
                    editSubscriptionData({ "_id": location.state._id, "title": values.title, "monthPrice": monthPrice, "yearPrice": yearPrice, "description": descriptionDataSend }, (result) => {
                        if (result.status) {
                            Swal.fire({
                                title: 'Status!',
                                text: 'Record is Successfully Updated',
                                timer: 2000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'success'
                            })
                            navigate('/dashboard/subscription');
                        }
                    })
                )
            }
            else {
                dispatch(
                    postSubscriptionData({ "title": values.title, "monthPrice": monthPrice, "yearPrice": yearPrice, "description": descriptionDataSend }, (result) => {
                        if (result.status) {
                            Swal.fire({
                                title: 'Status!',
                                text: 'Record is Successfully Submitted',
                                timer: 2000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'success'
                            })
                            navigate('/dashboard/subscription');
                        }
                    })
                )
            }
        },
    });
    return (
        <>
            <Page title="Create New Subscription">
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography variant="h4" gutterBottom>
                            {!(location?.state) ? 'Create New Subscription' : 'Update Subscription'}
                        </Typography>
                    </Stack>
                    <Card>
                        <Box style={{ display: "flex", padding: '24px' }}>
                            <Grid container xs={10} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        type="text"
                                        placeholder='Plan Name'
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        helperText={formik.errors.title}
                                        error={!!formik.errors.title}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="monthPrice"
                                        name="monthPrice"
                                        type="Number"
                                        placeholder='Month Price'
                                        onChange={(event) => {
                                            const {
                                              target: { value },
                                            } = event;
                                            setMonthPrice(value);
                                          }}
                                        value={monthPrice}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        helperText={formik.errors.monthPrice}
                                        error={!!formik.errors.monthPrice}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="yearPrice"
                                        name="yearPrice"
                                        type="Number"
                                        placeholder='Year Price'
                                        // onChange={formik.handleChange}
                                        onChange={(event) => {
                                            const {
                                              target: { value },
                                            } = event;
                                            setYearPrice(value);
                                          }}
                                        value={yearPrice}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        helperText={formik.errors.yearPrice}
                                        error={!!formik.errors.yearPrice}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{display:'flex'}}>
                                    {/* <TextField
                                        id="description"
                                        name="description"
                                        type="text"
                                        placeholder='Description'
                                        onChange={formik.handleChange}
                                        // value={formik.values.description}
                                        value={formik.values.description.map((arrVal) => {
                                            return arrVal.title
                                        })
                                        }
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        helperText={formik.errors.description}
                                        error={!!formik.errors.description}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    /> */}
                                    <Grid item xs={8}>
                                        <TextField
                                            id="newDescription"
                                            name="newDescription"
                                            type="text"
                                            placeholder='New Description'
                                            value={newDescription}
                                            onChange={(e) => {
                                                setNewDescription(e.target.value);
                                            }}
                                            fullWidth
                                            helperText={formik.errors.description}
                                            error={!!formik.errors.description}
                                            onBlur={formik.handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{marginLeft:'0.5rem'}}>
                                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => {
                                            if(newDescription && !descriptionDataSend.filter(value=> value.title===newDescription).length){
                                                setDescriptionData([...descriptionData, { title: `${newDescription}` }])
                                                setDescriptionDataSend([...descriptionDataSend, { title: `${newDescription}` }])
                                                formik.errors.description='';
                                            }
                                                }}>Add</Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {descriptionData?.map(arrVal => <div key={arrVal.title}><FormControlLabel control={<Checkbox defaultChecked value={arrVal.title} onChange={(e) => {
                                        if (!e.target.checked) {
                                            for (let i = 0; i < descriptionDataSend.length; i += 1) {
                                                if (descriptionDataSend[i].title === e.target.value) {
                                                    descriptionDataSend.splice(i, 1);
                                                    setDescriptionDataSend([...descriptionDataSend])
                                                    return
                                                }

                                            }
                                        }
                                        else {
                                            setDescriptionDataSend([...descriptionDataSend, { title: `${e.target.value}` }])
                                        }
                                    }} />} label={arrVal.title} /></div>)}
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" color="error" sx={{ marginRight: '10px', color: 'white',"@media (max-width:432px)":{marginBottom:'0.4rem'} }} onClick={() => {
                                        navigate(-1);
                                    }}>
                                        Cancel
                                    </Button>
                                    {<Button variant="contained" color="success" sx={{ marginRight: '10px', color: 'white'}} onClick={formik.handleSubmit}>
                                        {location?.state?'Update':'Submit'}
                                    </Button>}
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Container>
            </Page>
        </>
    )
}

export default CreateNewRecords