// component
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getUserIcon = () => <img className='groups_icon' src={'/static/icons/group.png'} alt={'group'} /> ;
const getUserIconpng = () => <img className='groups_icon' src={'/static/icons/user_png.png'} alt={'grouppng'} /> ;
const getDashboardIcon = () => <img className='blues_icon' src={'/static/icons/pie-chart.png'} alt={'pie-chart'} /> ;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getDashboardIcon(),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getUserIconpng(), 
  },
  
  {
    title: 'Groop Management',
    path: '/dashboard/groop-management',
    icon: getUserIcon(),
  },
  // {
  //   title: 'Subscription',
  //   path: '/dashboard/subscription',
  //   icon: <SubscriptionsIcon sx={{color:'blueviolet'}}/>,
  // },
];



export default navConfig;
