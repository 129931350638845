import { TYPES } from '../Actions/UserActions';

const subscriptionReducer = (state = {},{ payload, type } )  => {
  switch (type) {
    case TYPES.SUBSCRIPTION_ADDED:
      return { ...payload.data };
    default:
      return state;
  }
};
export default subscriptionReducer;