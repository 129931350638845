
export const ApiEndPoint = {
    LOGIN: 'admin/admin/login',
    FORGOT: 'admin/admin/forgotPassword',
    VERIFY: 'admin/admin/verifyOtp',
    RESET: 'admin/admin/resetpassword',
    CREATEUSER: 'user/user/createProfile',
    RESENDOTP: 'admin/admin/resendOtp',
    LOGOUT: 'admin/admin/logout',
    USERLIST: 'admin/admin/getAllUser',
    GROUPLIST: 'admin/admin/getAllGroup',
    CHANGEPASSWORD:'admin/admin/changePassword',
    BLOCKUNBLOCK:'admin/admin/blockUnblock',
    GROUPSTATUS:'admin/admin/updateGroupStaus',
    USERNAMEVALIDATE: 'admin/admin/checkUserName',
    LISTSUBSCRIPTION:'admin/subscription/listSubscription',
    POSTSUBSCRIPTION:'admin/subscription/createSubscription',
    REMOVESUBSCRIPTION:'admin/subscription/removeSubscription',
    EDITSUBSCRIPTION:'admin/subscription/updateSubscription',
    USERALLDATA:'admin/admin/dashboard',
    DELETEGROUP:'admin/admin/deletegroup',
    DELETEUSER: 'admin/admin/delete/user'
}