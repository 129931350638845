import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faker } from '@faker-js/faker';
// @mui
import { Doughnut } from 'react-chartjs-2';
import { Chart as chartJS } from 'chart.js/auto';

import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, InputLabel, Select, MenuItem, Box } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import { userList, TYPES, userListDashboard } from '../Redux/Actions/UserActions';
import { getToken, getUser } from '../Redux/Selectors/UserSelectors';
import { HttpClient } from '../Redux/Controllers';

import { UserData } from './UserData';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const accessToken = useSelector(getToken);
  const [userfilter, setuserfilter] = useState(0);
  const [subsfilter, setsubsfilter] = useState(0);
  const [groupfilter, setgroupfilter] = useState(0);
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: 'User Status',
        data: [],
        backgroundColor: ['#94C83D', '#2261AE'],
        borderWidth: 2,
      },
    ],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
    },
  };
  const [subscribtionData,setSubscribitionData]=useState(0);
  useEffect(() => {
    HttpClient.setAuthorization(accessToken);
    dispatch(
      userListDashboard({ page: 0, rowsPerPage: 0 }, (result) => {
        const activeUser = result?.data?.activeUser;
        const BlockedUser = result?.data?.inactiveUsers;
        setSubscribitionData(result?.data?.subscribeUser);
        const graphUserData = [
          {
            id: 1,
            status: 'Active',
            user: activeUser,
          },
          {
            id: 2,
            status: 'Inactive',
            user: BlockedUser,
          },
        ];
        setUserData({
          labels: graphUserData?.map((data) => data.status),
          datasets: [
            {
              label: 'User Status',
              data: graphUserData?.map((data) => data.user),
              backgroundColor: ['#94C83D', '#2261AE'],
              borderWidth: 2,
            },
          ],
        });
      })
    );
  }, [accessToken]);

  // const group = {
  //   labels: ['Outer Circle', 'Inner Circle', 'Public Circle'],
  //   datasets: [
  //     {
  //       label: 'Group Management',
  //       data: [300, 50, 100],
  //       backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  const data = {
    labels: ['Total Users','Subscriptions'],
    datasets: [
      {
        label: 'Subscriptions',
        data: [(userData.datasets[0].data[0] + userData.datasets[0].data[1]), subscribtionData],
        backgroundColor: ["#C4CDD5",'#94C83D'],
        borderWidth: 2,
      },
    ],
  };

  const changeUserFilter = (event) => {
    console.log(event.target.value);
    setuserfilter(event.target.value);
  };

  const changeSubsFilter = (event) => {
    console.log(event.target.value);
    setsubsfilter(event.target.value);
  };

  const changeGoupFilter = (event) => {
    console.log(event.target.value);
    setgroupfilter(event.target.value);
  };

  return (
    <Page title="Dashboard">
      <Container spacing={2}>
        <Typography className='heading-h4' variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        {/* <Grid className='filtered' item xs={12}>
          <Select id="select-subscription" value={subsfilter} onChange={changeSubsFilter}>
            <MenuItem value="0">Filters</MenuItem>
            <MenuItem value="24">Last 24 Hours</MenuItem>
            <MenuItem value="week">Last 1 week</MenuItem>
            <MenuItem value="month">Last 1 month</MenuItem>
            <MenuItem value="year">Last 1 year</MenuItem>
          </Select>
        </Grid> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className='chart_box '>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ borderRight: '1px solid #EEEEEE', padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {/* <h3 spacing={2}>User Status</h3> */}
                  <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>User Status</Box>
                  <Doughnut data={userData} options={options} style={{ marginLeft: '-1rem', marginTop: '2rem' }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <h4>Total User</h4> */}
                    <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Total User</Box>
                    {(userData.datasets[0].data[0] + userData.datasets[0].data[1])||0}
                  </Box>
                  <Box component="div" sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '45px',"@media (max-width:432px)":{
                    flexDirection:"column",alignItems:"flex-start" }}}>
                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#94C83D' }} />
                        </Box>
                        <Box component="div">
                          {/* <h4>Active</h4> */}
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Active</Box>
                          {userData.datasets[0].data[0]}
                        </Box>
                      </Box>
                    </Box>
                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#2261AE' }} />
                        </Box>
                        <Box component="div">
                          {/* <h4>Inactive</h4> */}
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Inactive</Box>
                          {userData.datasets[0].data[1]}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>

          {/* <Grid item xs={2}>
            <Select id="select-user" value={userfilter} onChange={changeUserFilter}>
              <MenuItem value="0">Filters</MenuItem>
              <MenuItem value="24">Last 24 Hours</MenuItem>
              <MenuItem value="week">Last 1 week</MenuItem>
              <MenuItem value="month">Last 1 month</MenuItem>
              <MenuItem value="year">Last 1 year</MenuItem>
            </Select>
          </Grid> */}


          <Grid item xs={12} md={6}>
            <div className='chart_box '>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ borderRight: '1px solid #EEEEEE', padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Subscription</Box>
                  <Doughnut data={data} options={options} style={{ marginLeft: '-1rem', marginTop: '2rem' }} />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {/* <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4>Total User</h4>
                    <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Total User</Box>
                    {(userData.datasets[0].data[0] + userData.datasets[0].data[1])||0}
                  </Box> */}
                  <Box component="div" sx={{ display: 'flex', justifyContent: 'center', marginTop: '45px',"@media (max-width:432px)":{
                    flexDirection:"column",alignItems:"flex-start",marginTop: '12px' } }}>
                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#C4CDD5' }} />
                        </Box>
                        <Box component="div">
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Total Users</Box>
                          {(userData.datasets[0].data[0] + userData.datasets[0].data[1])||0}
                        </Box>
                        {/* <Box component="div">
                          <h4>Data1</h4> 
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data1</Box>
                          {data.datasets[0].data[0]}
                        </Box> */}
                      </Box>
                    </Box>
                    {/* <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#2261AE' }} />
                        </Box>
                        <Box component="div">
                           <h4>Data2</h4> 
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data2</Box>
                          {data.datasets[0].data[1]}
                        </Box>
                      </Box>
                    </Box> */}
                    {/* <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#C63F1E' }} />
                        </Box>
                        <Box component="div">
                           <h4>Data3</h4>
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data3</Box>
                          {data.datasets[0].data[2]}
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                  <Box component="div" sx={{ display: 'flex', justifyContent: 'center', marginTop: '45px',"@media (max-width:432px)":{
                    flexDirection:"column",alignItems:"flex-start",marginTop: '12px' } }}>
                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#94C83D' }} />
                        </Box>
                        <Box component="div">
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Subscribed</Box>
                          {subscribtionData}
                        </Box>
                        {/* <Box component="div">
                          <h4>Data1</h4> 
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data1</Box>
                          {data.datasets[0].data[0]}
                        </Box> */}
                      </Box>
                    </Box>
                    {/* <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#2261AE' }} />
                        </Box>
                        <Box component="div">
                           <h4>Data2</h4> 
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data2</Box>
                          {data.datasets[0].data[1]}
                        </Box>
                      </Box>
                    </Box> */}
                    {/* <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="div" sx={{ alignSelf: 'flex-start', marginTop: '0.3rem' }}>
                          <Box component="div" sx={{ marginRight: '10px', height: '16px', width: '16px', borderRadius: '50%', backgroundColor: '#C63F1E' }} />
                        </Box>
                        <Box component="div">
                           <h4>Data3</h4>
                          <Box component='div' spacing={2} sx={{fontSize: '1.0rem',fontWeight:'Bold'}}>Data3</Box>
                          {data.datasets[0].data[2]}
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item xs={10}>
            <h3 spacing={2}>Group Management</h3>
            <Pie data={group} />
          </Grid> */}

          {/* <Grid item xs={2}>
            <Select id="select-group" value={groupfilter} onChange={changeGoupFilter}>
              <MenuItem value="0">Filters</MenuItem>
              <MenuItem value="24">Last 24 Hours</MenuItem>
              <MenuItem value="week">Last 1 week</MenuItem>
              <MenuItem value="month">Last 1 month</MenuItem>
              <MenuItem value="year">Last 1 year</MenuItem>
            </Select>
          </Grid> */}
        </Grid>
      </Container >
    </Page >
  );
}
