import { ApiEndPoint } from './ApiEndPoint';
import { HttpClient } from './HttpClient'

export class UserController {
  static login(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.LOGIN, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }

  static forgot(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.FORGOT, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }


  static changePassword(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.CHANGEPASSWORD, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }


  static blockUnblock(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.BLOCKUNBLOCK, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }

  static groupStatus(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.GROUPSTATUS, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }


  static reset(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.RESET, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }

  static resendotp(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.RESENDOTP, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }

  static verification(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.VERIFY, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        })
    });
  }

  static createUserDetails(userdata) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.CREATEUSER, userdata)
        .then(data => {
          // let data = Object.assign(response.data[0], response.data[1]);
          resolve(data);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static uploadimage(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.UPLOADIMAGE, params)
        .then(response => {
          resolve(response);
        }).catch((e) => {

          reject(e);
        })
    });
  }

  static validateusername(id) {
    return new Promise((resolve, reject) => {
      if (String(id).length > 0) {
        HttpClient.post(ApiEndPoint.USERNAMEVALIDATE, { userName: id })
          .then(response => {
            resolve(response);
          }).catch((e) => {
            reject(e);
          })
      }
    });
  }

  static logout() {
    return new Promise((resolve, reject) => {
      HttpClient.post(ApiEndPoint.LOGOUT)
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static userList(params) {
    let url;
    if(params?.filterName){
      url=`${ApiEndPoint.USERLIST}?pageNo=${params.page}&limit=${params.rowsPerPage}&text=${params.filterName}`;
    }
    else {
      url=`${ApiEndPoint.USERLIST}?pageNo=${params.page}&limit=${params.rowsPerPage}`
    }
    return new Promise((resolve, reject) => {
      HttpClient.get(url)
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static userListDashboard(params) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`${ApiEndPoint.USERALLDATA}`)
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static groupList(params) {
    let url;
    if(params?.filterName){
      url=`${ApiEndPoint.GROUPLIST}?pageNo=${params.page}&limit=${params.rowsPerPage}&text=${params.filterName}`;
    }
    else {
      url=`${ApiEndPoint.GROUPLIST}?pageNo=${params.page}&limit=${params.rowsPerPage}`
    }
    return new Promise((resolve, reject) => {
      HttpClient.post(url)
        .then(response => {
          resolve(response);
        }).catch(e => {
          // if(!e){
          //   const error={message:'No Internet'};
          //         reject(error);
          // }
          // else reject(e);
          reject(e);
        })
    });
  }

  static getListSubscription(params) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`${ApiEndPoint.LISTSUBSCRIPTION}`)
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static postSubscriptionData(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`${ApiEndPoint.POSTSUBSCRIPTION}`,{"title":params.title,"monthPrice":params.monthPrice,"yearPrice":params.yearPrice,"description":params.description})
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static removeSubscriptionData(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`${ApiEndPoint.REMOVESUBSCRIPTION}`,{"_id":params.id})
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static editSubscriptionData(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`${ApiEndPoint.EDITSUBSCRIPTION}`,{"_id":params._id,"title":params.title,"monthPrice":params.monthPrice,"yearPrice":params.yearPrice,"description":params.description})
        .then(response => {
          /* eslint-disable no-debugger */
  debugger;
  /* eslint-enable no-debugger */
          resolve(response);
        }).catch(e => {
          /* eslint-disable no-debugger */
  debugger;
  /* eslint-enable no-debugger */
          reject(e);
        })
    });
  }
  
  static deleteGroupManagement(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`${ApiEndPoint.DELETEGROUP}`,{"groupId":params.groupId})
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }

  static deleteUser(params) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`${ApiEndPoint.DELETEUSER}/${params.userId}`)
        .then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    });
  }
}


