import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import {
  Stack,
  Container,
  Typography,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  CircularProgress,
  TablePagination,
  Grid
} from '@mui/material';
import { useSelector, useDispatch,shallowEqual } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { UserListHead } from '../sections/@dashboard/user';
import SubsListToolbar from '../sections/@dashboard/subscription/SubsListToolbar';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Label from '../components/Label';
import { errorsSelector } from '../Redux/Selectors/ErrorSelectors';
import { isLoadingSelector } from '../Redux/Selectors/StatusSelectors';
import { getToken } from '../Redux/Selectors/UserSelectors';
import { getListSubscription, removeSubscriptionData,TYPES } from '../Redux/Actions/UserActions';
import { HttpClient } from '../Redux/Controllers';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const res = filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);

    if (res.length > 0) {
      return res;
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

const Subscription = () => {
  const accessToken = useSelector(getToken);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [USERLIST, setUSERLIST] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [tableValue, setTableValue] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const MySwal = withReactContent(Swal);
  const [totalData, setTotalData] = useState(0);
  const navigate = useNavigate();

  const errors = useSelector((state) => errorsSelector([TYPES.LIST_SUBS], state), shallowEqual);
  const isLoading = useSelector((state) => isLoadingSelector([TYPES.LIST_SUBS], state));
  const filteredUsers = applySortFilter(tableValue, getComparator(order, orderBy), filterName);
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleClearFilterByName = (event) => {
    setFilterName('');
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  useEffect(() => {
    HttpClient.setAuthorization(accessToken);
    dispatch(
      getListSubscription({}, (result) => {
        setTableValue(result?.data);
        setTotalData(result?.data.length);
      })
    );
    setUpdateTable(true);
  }, [updateTable])

  const removeItem = (id) => {
    dispatch(
      removeSubscriptionData({ id }, (result) => {
        setUpdateTable(!updateTable);
        Swal.fire('Status!', 'Record is Successfully Removed', 'success');
      })
    );
  }

  const removeItemMessage = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the record',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        removeItem(id);
      }
    });


  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: 'sNumber', label: 'S.No.', alignRight: false },
    { id: 'planName', label: 'Plan Name', alignRight: false },
    { id: 'monthlyPrice', label: 'Monthly Price', alignRight: false },
    { id: 'lastName', label: 'Yearly Price', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'update', label: 'Update', alignRight: false },
    { id: 'remove', label: 'Remove', alignRight: false },
    // { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' },
  ];
  return (
    <>
    {errors && errors}
      <Page title="Subscription">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Subscription
            </Typography>
          </Stack>
          <Card>
            <Box component='div' sx={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center', "@media (max-width:432px)": {
                flexDirection: "column", alignItems: "flex-start"
              }
            }}>
              <SubsListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onClearFilterName={handleClearFilterByName}
              />
              <Button variant="contained" color="success" startIcon={<AddIcon />} sx={{ marginRight: '10px', color: 'white', "@media (max-width:432px)": { marginLeft: "25px" } }} onClick={() => {
                navigate('/dashboard/createNewRecord');
              }}>
                New Plan
              </Button>
            </Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table className='sub-mgmt'>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  {isLoading ?
                    <TableRow>
                      <TableCell colSpan={12} align='center' sx={{ border: 'none' }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                    :
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                        /* eslint-disable no-debugger */
                        debugger;
                        /* eslint-enable no-debugger */
                        const { _id, title, planName, monthPrice, yearPrice, description, active } = row;
                        const isItemSelected = selected.indexOf(planName) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell className="d-none" align="left">{row.id}</TableCell>
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, firstname)} />
                        </TableCell> */}
                            <TableCell>{index + (rowsPerPage * page) + 1}</TableCell>
                            <TableCell align="left">{title}</TableCell>
                            <TableCell align="left">$ {monthPrice}</TableCell>
                            <TableCell align="left">$ {yearPrice}</TableCell>
                            <TableCell align="left">{description?.map((val,index) => {
                              return <React.Fragment key={val.title}>{val.title}{(description.length-1!==index)?',':''}</React.Fragment>
                            })}</TableCell>
                            <TableCell align='left'>
                              <Button variant="text" startIcon={<EditIcon />} sx={{ width: 'max-content', border: 'none' }} onClick={() => {
                                navigate('/dashboard/createNewRecord', { state: { _id, title, monthPrice, yearPrice, description } });
                              }}>
                                Edit
                              </Button>
                            </TableCell>
                            <TableCell align='left'>
                              <Button variant="text" startIcon={<RemoveCircleIcon />} sx={{ width: 'max-content', color: 'red', border: 'none' }} onClick={() => {
                                removeItemMessage(_id);
                              }}>
                                Remove
                              </Button>
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                // changeStatus(!isBlocked, _id);
                              }}
                            >
                              <Label variant="ghost" color={(active === true && 'success') || 'error'}>
                                {active === true ? 'Active' : 'Blocked'}
                              </Label>
                            </TableCell>
                            {/* <TableCell align="left">
                          <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}

                            <TableCell align="right">{/* <UserMoreMenu /> */}</TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )} */}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  )
}

export default Subscription